import './bootstrap';
import { EsteiraTech } from './esteiratech';

EsteiraTech.add('ajax',(params={},callback=()=>{})=>{
    params.method = params.method ?? 'GET';
    let data = {
        method: params.method,
        headers: {
            'Content-Type':'application/x-www-form-urlencoded',
            'X-Requested-With':'XMLHttpRequest',
            'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content')
        },
    }
    if(params.body){
        data.body = params.body;
    }

    fetch(base_url+'/'+params.url, data).then(response => response.json()).then((response) => {
        callback(response)
        return response;
    })
})

EsteiraTech.add('openModal',(idModal,callback=()=>{})=>{
    eval("open_modal_"+idModal+"(callback)");
})

EsteiraTech.add('formatDateTimeToBr',(dateTimedb,callback=()=>{})=>{
    return moment(dateTimedb).format('DD/MM/YYYY HH:mm');
})

EsteiraTech.add('formatDateDifference',(dateTimedb,callback=()=>{})=>{
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    const rightNow = moment(new Date).format(dateFormat);
    const thisTimeYesterday = moment(dateTimedb).format(dateFormat);
    const differenceSeconds = moment(rightNow).diff(thisTimeYesterday, 'seconds');
    const differenceMinutes = moment(rightNow).diff(thisTimeYesterday, 'minutes');
    const differenceInHours = moment(rightNow).diff(thisTimeYesterday, 'hours');
    const differenceInDays = moment(rightNow).diff(thisTimeYesterday, 'days');
    const differenceInWeeks = moment(rightNow).diff(thisTimeYesterday, 'weeks');
    const differenceInMonths = moment(rightNow).diff(thisTimeYesterday, 'months');
    const differenceInYears = moment(rightNow).diff(thisTimeYesterday, 'years');
    if( differenceInYears ){
        return "há " + differenceInYears + " a"
    }else if( differenceInMonths ){
        return "há " + differenceInMonths + " m"
    }else if( differenceInWeeks ){
        return "há " + differenceInWeeks + " sem"
    }else if( differenceInDays ){
        return "há " + differenceInDays + " d"
    }else if( differenceInHours ){
        return "há " + differenceInHours + " h"
    }else if( differenceMinutes ){
        return "há " + differenceMinutes + " min"
    }else{
        return "há " + differenceSeconds + " s"
    }
})

EsteiraTech.add('loadMoreData',(containerScroll,containerHtml,url,callback=(load)=>{})=>{

    EsteiraTech.onChange('loadMoreParams',function(val) {
        EsteiraTech.loading.in(containerScroll,'loadMoreDataLoading');
        EsteiraTech.$.ajax({
            'url': url+ '?page=' + val.page + '&s=' + val.s+(EsteiraTech.$get('chatDataGet') || ''),
            'method': 'GET'
        },(response)=>{
            if(response.html || response.html == ""){
                if( val.page == 1 ){
                    $(containerHtml).empty();
                }
                $(containerHtml).append(response.html);
                window.setTimeout(function(){
                    if( val.idConversation != 'undefined' && val.idConversation ){
                        $(".chat-item-message[chat-id-user="+val.idConversation+"]").click();
                    }
                },50);
                callback(response)
            }
            EsteiraTech.loading.out('loadMoreDataLoading');
        });
    })

    $(containerScroll).scroll(function() {
        if($(containerScroll).scrollTop() + $(containerScroll).height() >= $(containerHtml).height()) {
            let params = (
                EsteiraTech.$get('loadMoreParams') ?
                EsteiraTech.$get('loadMoreParams') : {}
            );
            params.page = ( typeof params.page != 'undefined' ? params.page + 1 : 2 );
            params.s = ( typeof params.s != 'undefined' ? params.s : '' );
            console.log(params)
            EsteiraTech.set(
                'loadMoreParams',
                params
            )
        }
    });

})

EsteiraTech.add('formatCep',(e)=>{
    var v= e.target.value.replace(/\D/g,"")
    v=v.replace(/^(\d{5})(\d)/,"$1-$2")
    e.target.value = v;
})

EsteiraTech.init();
